<template>
  <div class="AsrConsumption page">
    <div class="top">
      <!-- <a-input placeholder="序号"></a-input> -->
      <a-date-picker
        valueFormat="YYYY/MM/DD"
        v-model:value="value"
        @change="dateChange"
      />
      <!-- <a-button type="primary">搜索</a-button> -->
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      @change="pageChange"
      :pagination="{
        current: page,
        total: total,
        pageSize: pageSize,
        showSizeChanger: true,
        showTotal: (total) => `总共 ${total} 条`,
        pageSizeOptions: ['20', '50', '100'],
      }"
    >
      <template #create_time="{ record }">
        {{ formatDate(record.create_time) }}
      </template>
      <template #expire="{ record }">
        {{ record.expire && formatDate(record.expire) }}
      </template>
      <template #pay_type="{ record }">
        {{ payType(record.pay_type) }}
      </template>
      <template #reason="{ record }">
        {{ reasonType(record.reason) }}
      </template>
      <template #state="{ record }">
        {{ payState(record.state) }}
      </template>

      <template #operation="{ record }">
        <a-button
          type="primary"
          size="small"
          @click="handleClick(record.detail)"
          >详情</a-button
        >
      </template>
    </a-table>
    <a-modal
      v-model:visible="visibleDetail"
      title="支付套餐详情"
      :maskClosable="false"
      centered
      okText="确定"
      cancelText="取消"
      :width="700"
      @ok="visibleDetail = false"
    >
      <a-descriptions
        bordered
        :column="{ xxl: 3 }"
        v-for="item in detailList"
        :key="item.type"
        style="margin-bottom: 20px"
      >
        <a-descriptions-item label="类型">{{
          item.type === "phone_charges"
            ? "话费"
            : item.type === "asr"
            ? "ASR"
            : "短信"
        }}</a-descriptions-item>
        <a-descriptions-item label="套餐">{{
          item.description
        }}</a-descriptions-item>
        <a-descriptions-item label="金额">{{ item.money }}</a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { asrConsumption } from "../../service/finace";
import {
  formatDate,
  payType,
  reasonType,
  payState,
} from "../../utils/function";
const columns = [
  {
    title: "序号",
    dataIndex: "id",
  },
  // {
  //   title: "代理商ID",
  //   dataIndex: "agent_id",
  // },
  {
    title: "终端ID",
    dataIndex: "user_id",
  },
  {
    title: "支付金额",
    dataIndex: "money",
  },
  {
    title: "支付状态",
    dataIndex: "state",
    slots: {
      customRender: "state",
    },
  },

  {
    title: "订单号",
    dataIndex: "order_num",
  },

  {
    title: "支付方式",
    dataIndex: "pay_type",
    slots: {
      customRender: "pay_type",
    },
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    slots: {
      customRender: "create_time",
    },
  },
  // {
  //   title: "流水号",
  //   dataIndex: "payment_no",
  // },
  {
    title: "账单类型",
    dataIndex: "reason",
    slots: {
      customRender: "reason",
    },
  },
  {
    title: "有效时间",
    dataIndex: "expire",
    slots: {
      customRender: "expire",
    },
  },
  {
    title: "详情",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
  },
];
export default {
  name: "AsrConsumption",
  setup() {
    const state = reactive({
      data: [],
      total: undefined,
      page: 1,
      pageSize: 20,
      visibleDetail: false,
      detailList: [],
      value: undefined,
    });
    onMounted(() => {
      // console.log(handleFun(Date.now()));
      state.value = handleFun(Date.now());
      init();
    });
    function handleFun(ts) {
      var now = new Date(parseInt(ts));
      var year = now.getFullYear();
      var month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1;
      var date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
      return year + "/" + month + "/" + date;
    }
    const init = async () => {
      const res = await asrConsumption({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: state.pageSize,
        create_time: state.value,
      });
      console.log(res);
      state.data = res.data.data.list;
      state.total = res.data.data.total;
    };
    const pageChange = (e) => {
      state.page = e.current;
      state.pageSize = e.pageSize;
      init();
    };
    const handleClick = (item) => {
      console.log(item);
      state.detailList = item;
      state.visibleDetail = true;
    };
    const dateChange = (e) => {
      console.log(e);
      state.value = e;
      init();
    };
    return {
      columns,
      ...toRefs(state),
      pageChange,
      formatDate,
      payType,
      reasonType,
      payState,
      handleClick,
      dateChange,
    };
  },
};
</script>

<style scoped>
.AsrConsumption {
  padding: 10px;
  overflow: auto;
}
.top {
  margin-bottom: 15px;
}
.top .ant-input,
.top .ant-select {
  width: 180px;
  margin-right: 20px;
}
.top .ant-btn {
  margin-right: 20px;
}
</style>